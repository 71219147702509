import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import './RefineryFaq.css'

const RefineryFaq = () => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>What is the functionality of vegetable oil refinery?</Accordion.Header>
                <Accordion.Body>
                    Refining process required to take out the unwanted material for human consumption in the crude extracted oil. There are different methods used as physical and chemical refining.                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>What is physical and chemical refinery?</Accordion.Header>
                <Accordion.Body>
                    Depend upon the composition of unwanted material in the oil define the method of process maintaining the nutritional values in the oil. in the physical refinery, the FFA will be separated from the oil by thermal process, in the chemical refining, the FFA will be removed by treating with alkali. The by-product comes as FFA in the physical refining and as soap stock in the chemical refining.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>What are all the steps in chemical refining?</Accordion.Header>
                <Accordion.Body>
                    Degumming, neutralization, washing, bleaching, dewaxing, deodorization and winterization.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>	What are all the component removed in the degumming process?</Accordion.Header>
                <Accordion.Body>
                    Hydratable and non-hydratable phosphatides, colours, odors and other solid impurities.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>What are all the component removed in the neutralization process? </Accordion.Header>
                <Accordion.Body>
                    FFA, phosphatides, metals and pigments.</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>What are all the components removed in the bleaching process?</Accordion.Header>
                <Accordion.Body>
                    Phosphatides, pigments, oxidation products, metal & soaps.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>What are all the components removed in the Fractionation process?</Accordion.Header>
                <Accordion.Body>
                    Wax and solid triglycerides.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>What are all the additional process steps for converting liquid fat into solid fat?
                </Accordion.Header>
                <Accordion.Body>
                    Inter-esterification, hydrogenation, fractionation.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>What is the ppm of phosphorus in different oils?</Accordion.Header>
                <Accordion.Body>
                    Palm oil – 15 to 60 ppm, soya – 800 to 1200 ppm, sunflower – 300 to 500 ppm, rape seed – 200 to 1400 ppm, ground nut 100 to 300 ppm, cotton seed 400 – 1000 ppm, corn, 250 – 800 ppm, rice bran – 600 to 800 ppm, coconut oil – 10 – 20 ppm.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>What are all the components removed in the Fractionation process?</Accordion.Header>
                <Accordion.Body>
                    Wax and solid triglycerides.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
                <Accordion.Header>What are all the component removed in the degumming process?
                </Accordion.Header>
                <Accordion.Body>
                    Hydratable and non-hydratable phosphatides, colours, odors and other solid impurities.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
                <Accordion.Header>What are all the steps in chemical refining? </Accordion.Header>
                <Accordion.Body>
                    Degumming, neutralization, washing, bleaching, dewaxing, deodorization and winterization.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
                <Accordion.Header>What are all the steps in physical refining?</Accordion.Header>
                <Accordion.Body>
                    Pre-treatment of gums, bleaching, dewaxing, deacidification cum deodorization and winterization.




                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
                <Accordion.Header>	What is bleaching process?</Accordion.Header>
                <Accordion.Body>
                    Bleaching is an intermediate process by physical and chemical inter-action by sorbent with oil to reduce the colour pigment pro-oxidant, hydrolised product and phospholipid.





                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
                <Accordion.Header>What are all the main colours in the oil?</Accordion.Header>
                <Accordion.Body>
                    Chlorophyll, carotenol, lutein, xanthophyll, protein degraded product etc.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
                <Accordion.Header>How Mecpro reduce the bleaching earth consumption?</Accordion.Header>
                <Accordion.Body>
                    The oil entering to the process carry over, volatile material like water, soap ppm, impurities which inactivate the bleaching earth, mecpro use spent bleaching earth in closed circuit to deactivate the volatile material, hence fresh bleaching earth consumption reduce.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
                <Accordion.Header>What are all the components removed in the deacidification cum deodorization process? </Accordion.Header>
                <Accordion.Body>
                    FFA, oxidised products, pigment, volatile material, peroxides, anisidine and light quantities of sterols, hydro-carbon and vitamins



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
                <Accordion.Header>What are all the special features in the deodoriser of Mecpro?</Accordion.Header>
                <Accordion.Body>
                    Falling film heat raising to eliminate any deterioration of oil and FFA. 100% possible heat recovery and discharge the product as per the required temperature without using any water. Special designed of scrubber with velocity breaker will prevent any fatty acid trace escaped from the process. Less steam consumption and conversion of FFA to glyceride for using as feed stock for biodiesel process.



                </Accordion.Body>
            </Accordion.Item>

        </Accordion>
    )
}

export default RefineryFaq