import React from "react";
import "./Biodiesel.css";
import { Parallax } from "react-parallax"
import BiodieselFaq from "./BiodieselFaq";
import bio from "../components/Assets/Bio-diesel & esterification.webm"
import bioios from '../components/Assets/Bio-diesel & esterification.mp4'
import { Helmet } from "react-helmet";

const Biodeisel = () => {
  return (
    <>
      <Helmet>
        <title> Bio Diesel</title>
        <meta name="discription" content="The recent developed biodiesel production
            plant is consisted of hydro-cavity mixer
            efficient separation and distillation system.
            The pre-treatment for crude oil, used oil,
            sludge oil, will be performed with layer-
            esterification process. To reduce the input
            feed cost using layer esterification method." />
        <meta name="keyword" content="Bio Diesel, crude oil, used oil, sludge oil" />
      </Helmet>

      <div><h1 className="Refinery">BIO-DIESEL</h1>
      </div>
      <div className="refinaryContainer">
        <Parallax blur={0} bgImage=".\Images\biodiesel1.webp" strength={600}>
          <div className="HimgContainer">



          </div>

          {/* <h1 className="pageHeading">Bio-Diesel</h1> */}
        </Parallax>

        <section className="refinery1">
          <img className="refImg" src="./Images/untitled1-24@2x.webp" alt="Biodiesel-0" />
          <h5 className="ref1h5">The recent developed biodiesel production
            plant is consisted of hydro-cavity mixer
            efficient separation and distillation system.
            The pre-treatment for crude oil, used oil,
            sludge oil, will be performed with layer-
            esterification process. To reduce the input
            feed cost using layer esterification method.
          </h5>



        </section>

        <h1 className="pageHeading2">Biofuels are the future of energy in this nation and around the world </h1>




        <section className="refinery1">
          <img className="refImg" src="./Images/untitled2-6@2x.webp" alt="Biodiesel-4" />
          <h5 className="ref1h5">Bio-diesel manufacturing has to go
            through varies steps in process like degumming, bleaching, layer esterification, transesterification and distillation.The new design by MECPRO
            incorporated the latest design by process equipment which in terms significant reduction of input raw material
            cost as well as enhanced recovery
            and improved quality.

          </h5>

        </section>

        <section className="refVid">
          <img className="fracbigImg" src="./Images/3-1@2x.webp" alt="Biodiesel-1" />
        </section><br />



        <h5 className="mecpro1">The new hydro-cavitation mixing method supported with high shear mixing
          will reduce the reaction time and number of steps for completing the trans-
          esterification. The reaction time for trans-esterification is less than half an
          hour, with single stage operation and less methanol intake into the process.
          Compared to the multi stage reaction process adopted in most bio-diesel
          projects this helps in increasing the degree of esterification and will reduce
          the utility consumption significantly since methanol intake is much less
          than in the conventional process.

        </h5>

        <section className="refinery1">

          <img className="pomeimg" src="./Images/biodiesel4.webp" alt="Biodiesel-2" />

          <img className="pomeimg" src="./Images/Untitled-3.webp" alt="Biodiesel-3" />




        </section>



        <section className="refVid">

          <video className="vid new" controls>
            <source src={bio} type='video/webm' />
            <source src={bioios} type='video/mp4' />
          </video>
        </section>


        <h1 className="pageHeading2">DEPENDABLE QUALITY </h1>

        <h3 className="mecpro1"> Our process technologies are most widely used due to their simplicity,
          versatility & cost effectiveness</h3><br /><br />















      </div>
      <h1 className="faqHeading">Frequently Asked Questions!</h1>
      <BiodieselFaq />


    </>
  );
};

export default Biodeisel;
