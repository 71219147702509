import "./HomePage.css";
import About from "../components/About";
import Projects from "../components/Projects";
/* import Service from '../components/Service';*/
import Patents from "../components/Patents";
/* import Patent from './Patent'; */
import Testimonial from "../components/Testimonial";
import Clients from "../components/Clients";

/* import Review from '../components/Review' */
import Awardhom from "../components/Awardhom";

import HeadSlider from "../components/HeadSlider";
import ContactHome from "./ContactHome";
/* import patentcollage from '../components/Patentcollage'; */
import Award from "../components/Award";
import { Helmet } from "react-helmet";
import Service from "../components/Service";

/* import Awards from '../components/Awards'; */

const HomePage = () => {
  return (
    <>
      {/*    <Helmet>
        <title>Mecpro Heavy Engineering Limited</title>
        <meta
          name="description"
          content="Mecpro Heavy Engineering Ltd in India and Malaysia, a trusted and dependable ISO certified technology provider having over 36 years of experience in oils & fats, oleo-chemicals, surfactants, distilleries, biodiesel in India and across the globe. We also operate in other sector that include food processing, agriculture, cement, steel and Petro-chemical projects on a BOT basis with holding 17 Patents."
        />
        <meta
          name="keyword"
          content="Bio Diesel, hydrogenation, fractionation, Layer Esterification, Refinery, mecpro heavy engineering limited, palm oil mill, oil mill, palm kernel oil, oil extraxtion process, solvent extraction,"
        />
      </Helmet> */}
      <HeadSlider />
      <About />
      <Awardhom />
      <Projects />
      <Service />
      {/* <patentcollage /> */}
      {/* <Service /> */}

      <Testimonial />
      <Patents />
      {/* <Review /> */}
      <Award />
      <Clients />
      <div className="contactHome">
        <ContactHome />
      </div>
    </>
  );
};

export default HomePage;
