import { useEffect } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { useLocation } from "react-router-dom";
/* import Home from './Pages/Home'; */
import HomePage from "./Pages/HomePage";
import AboutUs from "./Pages/AboutUs";
/* import Service from './Pages/Service'; */
import Contact from "./Pages/Contact";
/* import TechnologyResearchEdibleO from './Pages/TechnologyResearchEdibleO'; */
import SustainabilityQualityManagem from "./Pages/SustainabilityQualityManagem";
import SolventExtraction from "./Pages/SolventExtraction";
import Refinery from "./Pages/Refinery";
import LayerEsterification from "./Pages/LayerEsterification";
import CottonSeed from "./Pages/CottonSeed";
import Soap from "./Pages/Soap";
import OilMill from "./Pages/OilMill";
import PalmOilMill from "./Pages/PalmOilMill";
import PalmKerneloilextraction from "./Pages/PalmKerneloilextraction";
import FatSplitting from "./Pages/FatSplitting";
import SpentBleechingEarth from "./Pages/SpentBleechingEarth";
import BakeryShortening from "./Pages/BakeryShortening";
import Hydrogenation from "./Pages/Hydrogenation";
import WaxPurification from "./Pages/WaxPurification";
import Pome from "./Pages/Pome";
import Biodiesel from "./Pages/Biodiesel";
import Fractionation from "./Pages/Fractionation";
import SolarEnergy from "./Pages/SolarEnergy";
import DesignandEngineering from "./Pages/DesignandEngineering";
import Palmfibreoilectraction from "./Pages/Palmfibreoilectraction";
import FlourMill from "./Pages/FlourMill";
import Foodprocessingresearch from "./Pages/Foodprocessingresearch";
import Greenenergyresearch from "./Pages/Greenenergyresearch";
import Soyabeanoilextraction from "./Pages/Soyabeanoilextraction";

import Ricebraneoiltextraction from "./Pages/Ricebraneoiltextraction";
import Mustardoilextraction from "./Pages/Mustardoilextraction";
/* import Sunfloweroilextraction from './Pages/Sunfloweroilextraction'; */
import Groundnutoilextraction from "./Pages/Groundnutoilextraction";
/* import Mangokarneloilextraction from './Pages/Mangokarneloilextraction'; */
import ResearchandDevelopment from "./Pages/ResearchandDevelopment";
import ExtractionSection from "./Pages/ExtractionSection";
import DesolventizationSection from "./Pages/DesolventizationSection";
import DistillationSection from "./Pages/DistillationSection";
import VentAirPurificationSystem from "./Pages/VentAirPurificationSystem";
import PatentandAwards from "./Pages/PatentandAwards";
import BootNav from "./components/BootNav";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Layout from "./Layout";
import Login from "./Pages/Login";
import Media from "./Pages/Media";

import Faq from "./Pages/Faq";
import ScrollTop from "./components/ScrollTop";
import EPCMEpcPmc from "./Pages/EPCMEpcPmc";
import Maintainance from "./Pages/Maintainance";
import Fabrication from "./Pages/Fabrication";
//google analytics
import ReactGA from "react-ga4";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import PalmFibreLanding from "./Pages/PalmFibreLanding";
import Tomato from "./Pages/Tomato";
import CreateBlog from "./Pages/CreateBlog";
import BlogPost from "./Pages/BlogPost";
import MiscellaSelfwash from "./Pages/MiscellaSelfwash";
import Lowtempmiscellaseperation from "./Pages/Lowtempmiscellaseperation";

ReactGA.initialize("G-562VF89ZEK");

function App() {
  /*  const location = useLocation();
   useEffect(() => {
     ReactGA.pageview(location.pathname + location.search);
   }, [location]); */
  return (
    <Router>
      <div className="App">
        <ScrollTop />
        <Header />
        <BootNav />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<HomePage />} />

            <Route path="/AboutUs" element={<AboutUs />} />
            {/*  <Route path='/TechnologyResearchEdibleO' element={<TechnologyResearchEdibleO />} /> */}
            <Route path="/SolventExtraction" element={<SolventExtraction />} />
            <Route path="/Refinery" element={<Refinery />} />
            <Route path="/Biodiesel" element={<Biodiesel />} />
            <Route
              path="/LayerEsterification"
              element={<LayerEsterification />}
            />
            <Route path="/CottonSeed" element={<CottonSeed />} />
            <Route path="/OilMill" element={<OilMill />} />
            <Route
              path="/SpentBleechingEarth"
              element={<SpentBleechingEarth />}
            />
            <Route path="/FatSplitting" element={<FatSplitting />} />
            <Route path="/Hydrogenation" element={<Hydrogenation />} />
            <Route path="/PalmOilMill" element={<PalmOilMill />} />
            <Route
              path="/PalmKerneloilextraction"
              element={<PalmKerneloilextraction />}
            />
            <Route
              path="/Palmfibreoilectraction"
              element={<Palmfibreoilectraction />}
            />
            <Route path="/Pome" element={<Pome />} />
            <Route path="/Soap" element={<Soap />} />
            <Route path="/BakeryShortening" element={<BakeryShortening />} />

            <Route path="/WaxPurification" element={<WaxPurification />} />
            <Route
              path="/SustainabilityQualityManagem"
              element={<SustainabilityQualityManagem />}
            />
            <Route path="/Fractionation" element={<Fractionation />} />
            {/*  <Route path='/service' element={<Service/>}/> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/SolarEnergy" element={<SolarEnergy />} />
            <Route
              path="/DesignandEngineering"
              element={<DesignandEngineering />}
            />
            <Route
              path="/Soyabeanoilextraction"
              element={<Soyabeanoilextraction />}
            />
            <Route path="/MiscellaSelfwash" element={<MiscellaSelfwash />} />

            <Route
              path="/Lowtempmiscellaseperation"
              element={<Lowtempmiscellaseperation />}
            />

            <Route
              path="/Ricebraneoiltextraction"
              element={<Ricebraneoiltextraction />}
            />
            <Route path="/FlourMill" element={<FlourMill />} />
            <Route
              path="/Foodprocessingresearch"
              element={<Foodprocessingresearch />}
            />
            <Route
              path="/Greenenergyresearch"
              element={<Greenenergyresearch />}
            />
            <Route
              path="/Mustardoilextraction"
              element={<Mustardoilextraction />}
            />
            {/*  <Route path='/Sunfloweroilextraction' element={<Sunfloweroilextraction />} /> */}
            <Route
              path="/Groundnutoilextraction"
              element={<Groundnutoilextraction />}
            />
            {/* <Route path='/Mangokarneloilextraction' element={<Mangokarneloilextraction />} /> */}
            <Route
              path="/ResearchandDevelopment"
              element={<ResearchandDevelopment />}
            />
            <Route path="/ExtractionSection" element={<ExtractionSection />} />
            <Route
              path="/DesolventizationSection"
              element={<DesolventizationSection />}
            />
            <Route
              path="/DistillationSection"
              element={<DistillationSection />}
            />
            <Route
              path="/VentAirPurificationSystem"
              element={<VentAirPurificationSystem />}
            />
            <Route path="/PatentandAwards" element={<PatentandAwards />} />
            <Route path="/media" element={<Media />} />
            <Route path="/create-blog" element={<CreateBlog />} />
            <Route path="/blog-post/:id" element={<BlogPost />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/epcmepcmc" element={<EPCMEpcPmc />} />
            <Route path="/maintainance" element={<Maintainance />} />
            <Route path="/fabrication" element={<Fabrication />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/PalmFibreLanding" element={<PalmFibreLanding />} />
            <Route path="/Tomato" element={<Tomato />} />
          </Route>
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
